

document.addEventListener('DOMContentLoaded', () => {
    const videoContainer = document.querySelector('.video-container');
    const cursorButton = document.querySelector('.cursor-button');

    videoContainer.addEventListener('mouseenter', () => {
        cursorButton.style.display = 'block';
    });

    videoContainer.addEventListener('mouseleave', () => {
        cursorButton.style.display = 'none';
    });

    videoContainer.addEventListener('mousemove', (e) => {
        const rect = videoContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Positionierung des Elements zentrieren
        cursorButton.style.left = `${x - 25}px`; // 25 is half the width of the cursorButton
        cursorButton.style.top = `${y - 25}px`; // 25 is half the height of the cursorButton
    });
});